import React, { ReactElement } from 'react';
import './palletFeeLine.scss';
import { useTranslation } from 'react-i18next';
import OrderLine from '../../../model/OrderLine';
import { Currency } from '../../../../shared/Currency';
import { Quantity } from '../../../../shared/components/Quantity';
import { getRoundedValues } from '../../../../shared/getRoundedValues';
import { PALLETS, PALLETS_SINGLE } from '../../../../../i18n/translations/TR';

interface PalletFeeLineProps {
    line: OrderLine;
}

export const PalletFeeLine = (props: PalletFeeLineProps): ReactElement => {
    const { t } = useTranslation();

    return (
        <div className="component-pallet-fee-line list-item">
            <div className="pallet-fee-line-description">
                <span className="title">
                    {props.line.description}
                </span>
            </div>

            <div className="pallet-fee-line-price-info">
                <div className="pallet-fee-line-price-description">
                    <Currency value={props.line.price.amount} />
                    <div className="separator">
                        x
                    </div>
                    <Quantity
                        value={props.line.quantity.amount}
                        label={props.line.quantity.amount > 1 ?
                            `${t(PALLETS).toLowerCase()}`
                            : `${t(PALLETS_SINGLE).toLowerCase()}`}
                    />
                </div>
                <div className="pallet-fee-line-total-price">
                    <Currency
                        className="import"
                        value={getRoundedValues(props.line.net.amount)}
                    />
                </div>
            </div>
        </div>
    );
};
